if (window.Aegis) {
  window.aegis = new Aegis({
    id: window.RUM_CONFIG.APPID, // 上报 id
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    api: {
      apiDetail: true, // 上报接口请求参数和返回值
      reportRequest: true, // 全量上报接口
      resHeaders: ['x-seq-id'], // 记录header
      retCodeHandler(data, url, xhr) {
        // data 是string类型，如果需要对象需要手动parse下  "{"result":{"code":6,"msg":"需要登录验证"},"data":{}}"
        // url 为请求url ttp://127.0.0.1:8324/api/iam/show_user_info
        // xhr 响应,可以通过xhr.response拿到完整的后台响应 xhr.response = "{\"result\":{\"code\":6,\"msg\":\"需要登录验证\"}}"
        try {
          data = JSON.parse(data);
          // eslint-disable-next-line no-empty
        } catch (e) {
          data = {};
        }
        // 不是当前碳LIVE 的接口
        if (!url.startsWith(window.location.origin)) {
          // 在接口白名单内
          if (whiteListScriptDomain.some((item) => url?.includes(item))) {
            // 请求状态码为[200 - 400)，认为正常请求，其余的是错误请求
            if (xhr.status < 200 || xhr.status >= 400) {
              // 状态码不为200，认为是错误请求，返回指定的错误状态码
              return { isErr: true, code: xhr.status };
            }
          }
          // 不在接口白名单内，不上报异常，直接返回true
          return { isErr: false, code: 0 };
        }
        return validReportRetCode(data);
      },
    },
    beforeRequest(log) {
      return isValidReportMsg(log) ? log : false;
    },
  });
}

/** 是否合法上报内容 */
/*
  { level: '1', name: '接口请求日志（白名单日志）' }
  { level: '2', name: '一般日志(aegis.info 或者 aegis.infoAll)' }
  { level: '4', name: 'JS 执行错误' }
  { level: '8', name: 'Promise 错误' }
  { level: '16', name: 'Ajax 请求异常' }
  { level: '32', name: 'JS 加载异常' }
  { level: '64', name: '图片加载异常' }
  { level: '128', name: 'css 加载异常' }
  { level: '256', name: 'console.error (未启用)' }
  { level: '512', name: '音视频资源异常' }
  { level: '1024', name: 'retcode 异常' }
  { level: '2048', name: 'aegis report' }
  { level: 4096, name: 'PV' }
  { level: 8192, name: '自定义事件' }
  { level: 16384, name: '小程序 页面不存在' }
  { level: 32768, name: 'websocket错误' }
  @return boolean valid
*/
const isValidReportMsg = (data) => {
  // Script error. 错误不进行上报
  if (data.logType === 'log'
    && data.logs?.level === '4'
    && data.logs?.msg?.includes('Script error.')) {
    return false;
  }

  // 当前路径404的时候也不上报
  if (data.logType === 'log'
    && data.logs?.level === '4'
    && is404PathName(window?.location?.pathname)) {
    return false;
  }

  // 由防火墙拦截 导致的 403 不上报
  if (data.logType === 'log'
    && data.logs?.level === '16'
    && data.logs?.msg?.includes('res status: 403')
    && data.logs?.msg?.includes('系统检测到您的请求可能对网站造成威胁')) {
    return false;
  }

  // 第三方脚本加载失败，不上报
  if (
    data.logType === 'log'
    && (data.logs?.level === '32' || data.logs.level === '128')
    && !whiteListScriptDomain.some((item) => data.logs?.msg?.includes(item)) // 错误资源匹配不到任意白名单domain
  ) {
    return false;
  }
  return true;
};

const is404PathName = (pathName) => {
  if (!pathName) return true;
  const whiteListPath = ['/', '/projects', '/logout'];
  return !whiteListPath.find(v => pathName.startsWith(v));
};

// 允许上报js，css错误的白名单domain
const whiteListScriptDomain = [
  'carbon.tencent.com', // prod
  'pre.carbon.tencent.com', // pre
  'res.wx.qq.com', // wx
  'cdn-go.cn', // rum
  // 'turing.captcha.qcloud.com', // 验证码
  'ossweb-img.qq.com', // onetrust
];

/**
 * 验证API接口 是否是需要上报的错误code
 * @param data 返回参数
 * @returns valid boolean
 */
const validReportRetCode = (data) => {
  if (data?.result?.code !== 0 && data?.result?.code !== 6) {
    return { isErr: true, code: data?.result?.code }; // retcode 异常错误
  }
  return { isErr: false, code: 0 }; // 其余情况都算正常
};

